.componentRoot {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoWrapper {
    width: 50vw;
}
