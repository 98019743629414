.componentRoot {
    padding: 5px 0;
    height: 100%;
    width: 100%;
}

.mainImage {
    width: 100%;
    height: 80%;
    object-fit: cover;
}

.title {
    font-size: 1.2em;
    font-weight: bold;
    padding: 5px 0;
    text-transform: uppercase;
}
