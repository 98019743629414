.componentRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 768px) {
    .componentRoot {
        justify-content: flex-start;
    }

    .componentRoot > a {
        margin: 0 1rem 0 0;
    }
}
