.componentRoot {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: justify;
    font-weight: bold;
    margin: 1vh auto;
    font-size: calc(25px + (15 + 15 * 0.7) * ((100vw - 320px) / 1200));
}

@media (min-width: 768px) {
    .title {
        font-size: calc(25px + 15 * (100vw / 1200));
    }
}

.description {
    display: flex;
    flex-direction: row;
    padding-top: 5vh;
    justify-content: center;
}

.description_image {
    display: none;
    margin: 0 1.2%;
}

@media (min-width: 768px) {
    .description_image {
        display: inline-block;
        height: 50vh;
    }
}

.description_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.1;
    text-align: justify;
    width: 80%;
    font-size: calc(16px + (2 + 2 * 0.7) * ((100vw - 320px) / 1200));
}

@media (min-width: 768px) {
    .description_text {
        width: 40%;
        line-height: 1.6;
        font-size: calc(16px + 2 * (100vw / 1200));
    }
}

.contact {
    white-space: pre-wrap;
    font-size: 0.8rem;
}

.contact a {
    font-weight: bold;
}
