.componentRoot {
    display: flex;
    justify-content: space-between;
}

.logoWrapper {
    width: 6rem;
}

@media (max-width: 767px) {
    .logoWrapper {
        width: 4rem;
    }
}
