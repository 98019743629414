.componentRoot {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    margin: 0 auto;
    padding: 0 5px;
}

@media (min-width: 481px) {
    .componentRoot {
        padding: 0 30px;
    }
}

.header {
    padding: 1rem 0 0 0;
}

.content {
    flex: 1 0 0;
    position: relative;
}

.footer {
    flex-shrink: 0;
    padding: 0 0 2rem 0;
}
