.componentRoot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.componentRoot > a {
    margin: 0.2rem 0;
    text-transform: uppercase;
}

.componentRoot > a:first-child {
    margin-top: 0;
}