* {
  font-family: 'Work Sans', sans-serif;
}

html,
body,
#root{
  height: 100%;
}

body {
  font-size: 16px;
}

a {
  color: black;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: red;
}

a:active {
  text-decoration: none;
}
