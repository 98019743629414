.title {
    font-size: 4em;
}

.description {
    font-size: 1.5em;
    white-space: pre-wrap;
}

.photoList {
    margin: 3em 0;
}

.photoWrapper {
    margin: 0.3em auto 0 auto;
}

@media (min-width: 768px) {
    .photoWrapper {
        width: 80%;
    }
}

.photo {
    width: 100%;
    object-fit: fill;
}
