.componentRoot {
    display: grid;
}

.cardContainer {
    display: flex;
    justify-content: center;
    margin: 0;
}

.cardContainer:hover {
    text-decoration: overline;
    color: black;
}

.cardWrapper {
    height: 500px;
    width: 100%;
}

@media (min-width: 481px) {
    .componentRoot {
        grid-template-columns: repeat(2, 1fr);
    }

    .cardWrapper {
        width: 90%;
    }
}

@media (min-width: 768px) {
    .componentRoot {
        grid-template-columns: repeat(3, 1fr);
    }
}


